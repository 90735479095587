<template>
    <div>
        <el-dialog
            width="70%"
            :visible.sync="batch"
            @close="handleClose"
        >
            <div
                class="box"
                v-if="model"
            >
                <h2>生成兑换码</h2>
                <el-form
                    class="b-card"
                    :model="model"
                    ref="form"
                    label-width="100px"
                    label-suffix="："
                >
                    <el-form-item label="添加数量">
                        <el-input
                            type="number"
                            style="width:200px;"
                            v-model="model.sum"
                            placeholder="请输入数量"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="有效期间">
                        <el-date-picker
                            v-model="model.selectTime"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="">
                        <el-button
                            size="medium"
                            type="primary"
                            @click="post"
                            native-type="submit"
                            :loading="loading"
                        >确定</el-button>

                    </el-form-item>
                </el-form>

                <div
                    class="b-card"
                    style="margin-top:30px;"
                >
                    <el-button
                      size="medium"
                      type="primary"
                      @click="downloadFile"
                    >导出兑换码</el-button>
                    <div class="coupon-code">
                        <div class="div">
                            <h4>未使用</h4>
                            <div class="coupon-code-box">
                                <p
                                    v-for="(item,index) in model.have"
                                    :key="index"
                                >{{item}};</p>
                            </div>
                        </div>
                        <div class="div">
                            <h4>已使用</h4>
                            <div class="coupon-code-box">
                                <code
                                    v-for="(item,index) in model.isUse"
                                    :key="index"
                                >{{item}};</code>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </el-dialog>
    </div>
</template>

<script>
import flatry from '@admin/utils/flatry'
import CouponService from '@admin/services/CouponService'
import FileService from '@admin/services/FileService'

export default {
  name: 'CouponCode',
  props: {
    BatchDialog: Boolean
  },
  data () {
    return {
      loading: false,
      batch: this.BatchDialog,
      model: null
    }
  },
  watch: {
    BatchDialog (val) {
      this.batch = val
    }
  },

  methods: {
    handleClose () {
      this.$emit('closeModel', false)
    },
    async getData (coupon) {
      const { data } = await flatry(CouponService.postCode(coupon.id))
      if (data) {
        let param = {
          sum: 100,
          coupon: coupon,
          have: data.data.have,
          isUse: data.data.isUse,
          selectTime: []
        }
        this.model = param
      }
    },
    async post () {
      this.loading = true

      const { data } = await flatry(
        CouponService.postCode(this.model.coupon.id, this.model)
      )
      if (data) {
        this.handleClose()

        this.$message.success(data.msg)
      }

      this.loading = false
    },
    async downloadFile () {
      let param = {
        type: 'coupon-code',
        id: this.model.coupon.id
      }
      const { data } = await flatry(FileService.downloadFile(null, param))
      if (data) {
        console.log(data)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.coupon-code{
    height: 300px;
    width: 100%;
    display: flex;
    .div{
        height: 300px;
        width: 50%;
    }
}
.coupon-code-box {
    height: 258px;
    max-height: 258px;
    width: 100%;
    overflow-y: auto;
}
</style>
