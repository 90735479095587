<template>
  <div class="box">
    <router-link
      v-if="$can('admin/coupon/create')"
      :to="{ path: '/coupon/create' }"
    >
      <el-button
        type="primary"
        size="medium"
      >
        新建优惠劵
      </el-button>
    </router-link>
    <div
      class="box-toolbar">
      <div class="box-item">
        <el-select
          v-model="typeid"
          placeholder="请选择"
           @change="changeTypesStatus"
        >
          <el-option
            v-for="(item,index) in types"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-item">
        <el-input
          placeholder="请输入关键字"
          v-model="name"

          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      :stripe="true"
      :data="lists"
      height="75vh"
    >
      <el-table-column
        width="90"
        prop="id"
        label="序号"
        fixed
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="优惠劵名称"
      >
      </el-table-column>
      <el-table-column
        width="150"
        prop="scoped_id"
        label="适用范围"
      >
      <template slot-scope="scope">
        <span v-if="scope.row.scoped_id === '1'">全场适用</span>
        <span v-if="scope.row.scoped_id === '2'">品类适用</span>
        <span v-if="scope.row.scoped_id === '3'">单品适用</span>
      </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="类型"
      >
      <template slot-scope="scope">
        <span v-if="scope.row.type === '1'">抵现劵</span>
        <span v-if="scope.row.type === '2'">折扣劵</span>
        <span v-if="scope.row.type === '3'">商品劵</span>
        <span v-if="scope.row.type === '4'">活动劵</span>
      </template>
      </el-table-column>
      <el-table-column
        prop="private"
        label="开启发放"
      >
       <template slot-scope="scope">
        <span v-if="scope.row.private === '1'">是</span>
        <span v-if="scope.row.private === '2'" style="color:red;">否</span>
      </template>
      </el-table-column>
      <el-table-column
        width="200"
        label="操作"
      >
        <template slot-scope="scope">

          <el-dropdown trigger="click">
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/coupon/update')"
                @click.native="handleModelEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/coupon/delete')"
                @click.native="handleModelDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

           <el-button
              style="margin-left:20px;"
              plain
              size="mini"
              type="primary"
              v-if="$can('admin/coupon/post-code')"
              @click="openModel(scope.row)"
            >
              兑换码
            </el-button>
        </template>
      </el-table-column>
    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    ></page>
    <Model ref="model" :BatchDialog="BatchDialog" @closeModel="closeModel"/>
  </div>
</template>

<script>
import CouponService from '@admin/services/CouponService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'
import Model from './Code'

export default {
  name: 'Coupon',
  data () {
    const TYPES = [
      {
        id: 0,
        name: '全部类型'
      },
      {
        id: 1,
        name: '抵现劵'
      },
      {
        id: 2,
        name: '折扣劵'
      },
      {
        id: 3,
        name: '商品劵'
      },
      {
        id: 4,
        name: '活动劵'
      }
    ]
    return {
      selectId: 0,
      BatchDialog: false,
      loading: true,
      function: function (param) {
        return CouponService.all(param)
      },
      delete: function (param) {
        return CouponService.delete(param)
      },
      typeid: '',
      types: TYPES,
      name: '' // 搜索关键字
    }
  },
  mixins: [pagination],
  components: { Page, Model },
  methods: {
    changeTypesStatus () {
      this.searchGo()
    },
    async searchGo () {
      this.page = 1
      let param = {
        type: this.typeid,
        name: this.name
      }
      this.where = param
      await this.getList(param)
    },
    closeModel () {
      this.BatchDialog = false
    },
    // 打开兑换码界面
    openModel (id) {
      this.$refs.model.getData(id)
      this.BatchDialog = true
    }
  }
}
</script>
<style lang='' scoped>
</style>
